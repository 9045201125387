<div id="mySidenav" class="sidenav">
  <div class="emblem">
    <img
      src="assets/tcs-logo.png"
      alt="tcs-emblem"
      width="40"
      height="40"
      id="emblem"
    />
    <span class="tcs-emblem-text">Online TCS</span>
  </div>
  <div class="drawer">
    <div
      class="parent"
      *ngFor="let item of sidebarItems; index as i"
      expanded="false"
      [attr.active]="checkActive(window.location.pathname, i)"
    >
      <div class="maintitle" (click)="click(i)">
        <div
          class="icon"
          [ngClass]="{ 'master-icon': item.GroupName == 'Masters' }"
          [inlineSVG]="item.MenuIconURL"
          (mouseenter)="hoverOnSide($event, i)"
          (mouseleave)="leaveOnSide($event, i)"
        ></div>
        <span class="sidenav-title">{{ item.GroupName }}</span>
      </div>
      <div class="anchor-group">
        <a
          class="subtitle"
          *ngFor="let subitem of item.SubMenus"
          [routerLink]="subitem.MenuURL"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [title]="subitem.PrivilegeName"
          (click)="handleClick(subitem.MenuURL)"
        >

          <!-- <span>•</span> &nbsp; -->
          <span class="sidenav-title">{{ subitem.PrivilegeName }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
