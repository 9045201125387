import { Inject, Injectable } from '@angular/core';
import { InstitutionMasterModel } from '@interfaces/college-model-interface';
import { Permissions } from '@interfaces/permissions.interface';
import { User } from '@interfaces/user';
import {
  ClassDetailForPromotion,
  CourseCredit,
  LookUpMaster,
  SubjectGroupMaster,
  TbSettings,
  tbCourse,
  tbSubjectGroupMaster,
} from 'src/app/curriculum/interfaces/departments.interface';
import { tbCourseSemesterYear } from 'src/app/timetable/interfaces/teacher-timetable.interface';
import { GetFormData } from '../helper-functons';
import {
  NAACHeirarchyMaster,
  NAACLevel,
} from '../interfaces/hierarchy.interface';
import { ApiHttpClient } from './api-http-client.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  public baseUrl: string | undefined;
  constructor(
    private http: ApiHttpClient,
    private AuthService: AuthService,
    @Inject('BASE_URL') base_url: string
  ) {
    this.baseUrl = base_url;
  }

  public get StaffID(): number {
    return this.AuthService.currentUserValue.StaffID;
  }
  public get DeptID(): number {
    return this.AuthService.currentUserValue.DepartmentID;
  }
  public get AcademicID(): number {
    return this.AuthService.currentUserValue.AcademicYearID;
  }
  public get LoginUserID(): number {
    return this.AuthService.currentUserValue.LoginUserID;
  }
  public get GroupID(): number {
    return this.AuthService.currentUserValue.GroupID;
  }
  public get UserID(): string {
    return this.AuthService.currentUserValue.UserID.toString();
  }
  public get InstitutionID(): number {
    return this.AuthService.InstitutionID;
  }
  public get UserName(): string {
    return this.AuthService.currentUserValue.Name;
  }
  public get User(): User {
    return this.AuthService.currentUserValue;
  }
  public get Institution(): InstitutionMasterModel {
    return this.AuthService.currentInstitutionValue!;
  }

  LookUp(lookup: number, Institution? : number ): Promise<LookUpMaster[]> {
    return this.http
      .get<LookUpMaster[]>('LookupDetails/Read', {
        params: {
          InstitutionID: Institution ?? this.InstitutionID,
          MasterItemID: lookup,
        },
      })
      .toPromise();
  }

  ReadByDescription(lookup: number, Institution? : number , Description?: string): Promise<LookUpMaster[]> {
    return this.http
      .get<LookUpMaster[]>('LookupDetails/ReadByDescription', {
        params: {
          InstitutionID: Institution ?? this.InstitutionID,
          MasterItemID: lookup,
          Description : Description
        },
      })
      .toPromise();
  }





  encrypt(text: string): string {
    return btoa(encodeURIComponent(text));
  }

  decrypt(text: string): string {
    return decodeURIComponent(atob(text));
  }

  GetPermissions(MenuURL: string): Promise<Permissions> {
    return this.http
      .get<Permissions>('UserLevelPrivileages/ReadUserURLPermission', {
        params: {
          UserID: this.UserID,
          GroupID: this.GroupID,
          MenuURL: MenuURL,
        },
      })
      .toPromise();
  }

  GetCourseTypes(): Promise<tbSubjectGroupMaster[]> {
    return this.http
      .get<tbSubjectGroupMaster[]>('CourseType/Read', {
        params: {
          InstitutionID: this.InstitutionID,
        },
      })
      .toPromise();
  }
  // Course/ReadStaffPermittedCourse(int StaffID, int AcademicYearID, int? SectorID)
  ReadStaffPermittedCourse(StaffID?: string, AcademicYearID?: number) {
    return this.http
      .get<tbCourse[]>('Course/ReadStaffPermittedCourse', {
        params: {
          StaffID: StaffID ?? this.StaffID,
          AcademicYearID: AcademicYearID ?? this.AcademicID,
        },
      })
      .toPromise();
  }

  //Read all pappers
  // ReadAllCourse(StaffID?:string, AcademicYearID?: number) {
  //   return this.http
  //     .get<tbCourse[]>('Course/ReadStaffPermittedCourse', {
  //       params: {
  //         StaffID: StaffID?? this.AcademicID,
  //         AcademicYearID: AcademicYearID ?? this.AcademicID,
  //       },
  //     })
  //     .toPromise();
  // }

  ReadAcademicYear() {
    return this.http
      .get<any[]>('AcademicYear/Read', {
        params: {
          InstitutionID: this.InstitutionID,
        },
      })
      .toPromise();
  }

  //OtherInstitution/Read
  ReadOtherInstitution() {
    return this.http.get<any[]>('OtherInstitution/Read').toPromise();
  }

  //SemesterYear/Read(int InstitutionID)
  ReadSemesters() {
    return this.http
      .get('SemesterYear/Read', {
        params: {
          InstitutionID: this.InstitutionID,
        },
      })
      .toPromise();
  }
  //SemesterYear/ReadByCourseID
  ReadSemestersByCourseID(CourseID: number | '%') {
    return this.http
      .get('SemesterYear/ReadByCourseID', {
        params: {
          InstitutionID: this.InstitutionID,
          AcademicYearID: this.AcademicID,
          CourseID,
        },
      })
      .toPromise();
  }
  // CourseSemesterYear/ReadStaffRightClasses(int StaffID, int AcademicYearID, int? CourseID)
  ReadStaffRightClasses(CourseID?: number) {
    return this.http
      .get<tbCourseSemesterYear[]>('CourseSemesterYear/ReadStaffRightClasses', {
        params: {
          StaffID: this.StaffID,
          AcademicYearID: this.AcademicID,
          CourseID: CourseID,
        },
      })
      .toPromise();
  }


  ReadByRegisterNo(
    RegisterNo: string
  )  {
    return this.http
      .get<any[]>('StudentMaster/ReadByRegisterNo', {
        params: {
          RegisterNo: RegisterNo,
        },
      })
      .toPromise();
  }


  ReadClassReps(
    CourseID: number,
    SemesterYearID: number,
    RunningStatusID: number
  ) {
    return this.http
      .get<any[]>('StudentMaster/ReadByCourseID', {
        params: {
          CourseID: CourseID,
          SemesterYearID: SemesterYearID,
          AcademicYearID: this.AcademicID,
          RunningStatusID: RunningStatusID,
        },
      })
      .toPromise();
  }

  GetCourseSemesterYear(
    CourseID: number | string,
    SemesterYearID: number | string,
    AcademicYearID?: number
  ): Promise<tbCourseSemesterYear[]> {
    return this.http
      .get<tbCourseSemesterYear[]>('CourseSemesterYear/Read', {
        params: {
          CourseID: CourseID,
          AcademicYearID: AcademicYearID ?? this.AcademicID,
          SemesterYearID: SemesterYearID,
        },
      })
      .toPromise();
  }




  GetClassesbyDepartments(StaffID: number,  DepartmentID: number  , AcademicYearID :number) {
    return this.http
      .get<tbCourseSemesterYear[]>('CourseSemesterYear/ReadStaffbyDepartment', {
        params: {
          StaffID,
          DepartmentID :  DepartmentID,
          AcademicYearID: AcademicYearID,
          InstitutionID: this.InstitutionID,
        },
      })
      .toPromise();
  }





  //FeeReceiptMaster/Read(int InstitutionID)
  ReadFeeReceiptMaster() {
    return this.http
      .get<any[]>('FeeReceiptMaster/Read', {
        params: {
          InstitutionID: this.InstitutionID,
        },
      })
      .toPromise();
  }

  //NAACHeirarchyMaster/Create([FromForm] IList<NAACLevel> naacLevels, NAACHeirarchyMaster naacHeirarchyMaster)
  CreateNAACHeirarchyMaster(
    naacLevels: NAACLevel[],
    naacHeirarchyMaster: NAACHeirarchyMaster
  ) {
    const form = GetFormData({
      naacLevels: naacLevels,
      naacHeirarchyMaster: naacHeirarchyMaster,
    });
    return this.http.post<any>('NAACHeirarchyMaster/Create', form).toPromise();
  }
  //NAACHeirarchyMaster/Read(int HeirarchyMasterID)
  ReadNAACHeirarchyMaster(HeirarchyMasterID: number) {
    return this.http
      .get<any[]>('NAACHeirarchyMaster/Read', {
        params: {
          HeirarchyMasterID: HeirarchyMasterID,
        },
      })
      .toPromise();
  }

  //Course/ReadByCategoryID(int HeirarchyMasterID)
  ReadCourseByCategoryID(CategoryID: number | '%', DepartmentID: number | '%') {
    return this.http
      .get<any[]>('Course/ReadByCategoryID', {
        params: {
          InstitutionID: this.InstitutionID,
          AcademicYearID: this.AcademicID,
          DepartmentID,
          CategoryID,
        },
      })
      .toPromise();
  }
  // ReadByDepartmentIDForDrillDown









  ReadByDepartmentIDForDrillDown(
    SemesterYearID: string,
    CourseCategoryID: number,
    DepartmentID: number
  ) {
    return this.http
      .get<any[]>('Paper/ReadByDepartmentIDForDrillDown', {
        params: {
          AcademicYearID: this.AcademicID,
          SemesterYearID: SemesterYearID,
          CourseCategoryID: CourseCategoryID,
          // InstitutionID: this.InstitutionID,
          DepartmentID: DepartmentID,
          LoginUserID: this.LoginUserID,
        },
      })
      .toPromise();
  }
  //StaffMaster/ReadByDepartmentID
  ReadByDepartmentIDfac(DepartmentID: number) {
    return this.http
      .get<any[]>('StaffMaster/ReadByDepartmentID', {
        params: {
          AcademicYearID: this.AcademicID,
          InstitutionID: this.InstitutionID,
          DepartmentID: DepartmentID,
          LoginUserID: this.LoginUserID,
        },
      })
      .toPromise();
  }



  ReadCourse(CourseCategoryID :any){

    return this.http
    .get<any[]>('Course/Read', {
      params: {
        CourseCategoryID :CourseCategoryID,
        InstitutionID: this.InstitutionID,
      },
    })
    .toPromise();

  }







  //ExportToPDF
  ExportToPDF(data: string, tempPath: string) {
    const form = GetFormData({
      data: data,
      tempPath: tempPath,
    });
    return this.http.post<any>('Reports/ExportToPDF', form).toPromise();
  }

  // pdf to docx
  PDF2DOCX(file: any) {
    const form = GetFormData({
      File: file,
    });
    return fetch(
      'https://v2.convertapi.com/convert/pdf/to/docx?Secret=x3uTSh8SG4AnkQps',
      {
        method: 'POST',
        body: form,
      }
    );
  }

  ReadCourseCredit(CourseID: string, AcademicPathwayID: string, SyllabusPatternID: number) {
    return this.http
      .get<CourseCredit[]>('Course/ReadCourseCredit', {
        params: {
          CourseID: CourseID,
          AcademicPathwayID: AcademicPathwayID,
          SyllabusPatternID: SyllabusPatternID
        },
      })
      .toPromise();
  }

  ReadSubjectGroupMaster(SyllabusPatternID: number) {
    return this.http
      .get<SubjectGroupMaster[]>('Course/ReadSubjectGroupMaster', {
        params: {
          SyllabusPatternID: SyllabusPatternID,
          InstitutionID: this.InstitutionID
        },
      })
      .toPromise();
  }

  DeleteCourseCreditDetails(CourseCreditDetailID: number,AcademicPathwayID: number,CourseTypeID: number,CourseID: number) {
    return this.http
      .get<CourseCredit[]>('Course/DeleteCourseCreditDetails', {
        params: {
          CourseCreditDetailID: CourseCreditDetailID,
          AcademicPathwayID: AcademicPathwayID,
          CourseTypeID: CourseTypeID,
          CourseID: CourseID
        },
      })
      .toPromise();
  }

  CreateCourseCredit(obj: CourseCredit):
  Promise<any[]> {
  const formData = GetFormData({
    obj,
  });

  return this.http.post<any>('Course/CreateCourseCredit', formData)
    .toPromise();
}

ReadSettingbyInstitutionIDForVoucher() {
  return this.http
    .get<TbSettings>('InstitutionMaster/ReadSettingbyInstitutionID', {
      params: {
        InstitutionID: this.InstitutionID,
      },
    })
    .toPromise();
}

}
