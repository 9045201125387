import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthUserGuard } from './shared/helpers/auth.guard';

const routes: Routes = [
  {
    path: 'authentication',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },


  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),

    canActivate: [AuthUserGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),

    canActivate: [AuthUserGuard],
  },
  {
    path: 'timetable',
    loadChildren: () =>
      import('./timetable/timetable.module').then((m) => m.TimetableModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'curriculum',
    loadChildren: () =>
      import('./curriculum/curriculum.module').then((m) => m.CurriculumModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'student-affairs',
    loadChildren: () =>
      import('./student-affairs/student-affairs.module').then(
        (m) => m.StudentAffairsModule
      ),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/setting.module').then(
        (m) => m.settingModule
      ),
    // canActivate: [AuthUserGuard],
  },
  {
    path: 'master',
    loadChildren: () =>
      import('./master/master.module').then((m) => m.MasterModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'events',
    loadChildren: () =>
      import('./events/events.module').then((m) => m.EventsModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'performance-evaluation',
    loadChildren: () =>
      import('./performance-evaluation/performance-evaluation.module').then(
        (m) => m.PerformanceEvaluationModule
      ),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'staff',
    loadChildren: () =>
      import('./staff/staff.module').then((m) => m.StaffModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'approvals',
    loadChildren: () =>
      import('./approvals/approvals.module').then((m) => m.ApprovalsModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'academics',
    loadChildren: () =>
      import('./academics/academics.module').then((m) => m.AcademicsModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'aqar',
    loadChildren: () => import('./aqar/aqar.module').then((m) => m.AqarModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'module',
    loadChildren: () =>
      import('./module/modules.module').then((m) => m.ModulesModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('./accounts/accounts.module').then((m) => m.AccountsModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'fee',
    loadChildren: () => import('./fee/fee.module').then((m) => m.FeeModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'security',
    loadChildren: () =>
      import('./security/security.module').then((m) => m.SecurityModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'editor',
    loadChildren: () =>
      import('./qp-editor/qp-editor.module').then((m) => m.QpEditorModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reporting/reporting.module').then((m) => m.ReportingModule),
    canActivate: [AuthUserGuard],
  },
  {
    path: '',
    redirectTo: 'dashboard/home',
    pathMatch: 'full',
  },
  {
    path: 'students',
    loadChildren: () =>
      import('./student/student.module').then((m) => m.StudentModule),
  },
  {
    path: 'examination',
    loadChildren: () =>
      import('./examination/examination.module').then((m) => m.ExaminationModule),
    canActivate: [AuthUserGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
