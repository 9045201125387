export enum EUserGroups {
  Student = 1,
  Teacher = 2,
  Parent = 3,
  Admin = 4,
  Alumni = 5,
  Management = 6,
  Faculty = 7,
}

export const Homes: {
  [key: number]: string;
} = {
  1: '/dashboard/home',
  2: '/dashboard/home',
  3: '/dashboard/home',
  4: '/dashboard/home',
  5: '/dashboard/home',
  6: '/dashboard/home',
  7: '/dashboard/home ',
}

export enum ModuleModuleID {
  'StudentAffairs' = 1,
  'Academics' = 2,
  'Fees' = 3,
  'Accounts' = 4,
  'Payroll & Staff' = 5,
  'Library' = 6,
  'Adminstration' = 7,
}
